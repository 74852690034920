@import "../../styles/variables";

.card {
  position: relative;
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
  box-shadow: $shadow;
  transition: 0.15s;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 400;
    color: $blue;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.image-container {
  height: 300px;
  background-position: center;
  background-size: cover;
  border-radius: 5px 5px 0 0;
  background-repeat: no-repeat;
}

.info-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 2rem;
}

.description {
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: $text-color;
}

.discount {
  position: absolute;
  padding: 0.5rem;
  background-color: $yellow;
  left: 0;
  top: 0;
  border-radius: 5px 0 5px 0;
  font-size: 1rem;
}

.priceBtnContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: $text-color;
}
