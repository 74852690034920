$bg-light: rgb(248, 249, 250);

$purple: #6f4bf2;
$lighter-purple: #8b6bffcc;
$blue: #236c99;
$yellow: #f2e14b;

$nav-text-color: #0000008c;
$nav-hover-color: #000000b3;
$nav-active-color: #000000b3;

$text-color: #000000b3;

$border-color: #dee2e6;

$small: 600px;
$medium: 996px;

$shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
