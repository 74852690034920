@import "../../styles/variables";

.contact-banner {
  background-image: linear-gradient(
      rgba(248, 249, 250, 0.6),
      rgba(248, 249, 250, 0.9),
      rgba(248, 249, 250, 0.6)
    ),
    url("../../assets/hero.jpg");
  background-position: center;
  background-size: cover;
  height: 20rem;
  color: $blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-form {
  display: flex;
  padding: 2rem;
  color: $text-color;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
  margin: 1rem auto;
  border-radius: 5px;
  box-shadow: $shadow;

  @media screen and (max-width: $small) {
    box-shadow: none;
    padding: 0;
  }

  h1 {
    margin-bottom: 3rem;
    align-self: flex-start;
  }

  label {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    border-radius: 5px;

    span {
      width: 85px;
    }

    input,
    textarea {
      flex: 1;
      border: none;
      transition: 0.2s;
      border: 1px solid $border-color;
      padding: 0.5rem;
      border-radius: 5px;

      &:focus {
        outline: none;
        box-shadow: $lighter-purple 0px 0px 0px 2px;
      }
    }
  }

  .btn {
    align-self: flex-end;
    min-width: 6rem;
    margin-top: 1rem;
  }

  .textarea-label {
    min-height: 200px;
    flex-direction: column;
    flex: 1;
    align-items: unset;

    textarea {
      resize: none;
    }
  }
}
