@import "../../styles/variables";

.search-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $bg-light;
  padding: 1rem;
}

.search-label {
  color: $text-color;
  font-size: 1.2rem;
}

.search {
  padding: 0.5rem;
  background-image: url("../../assets/search.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  border: none;
  max-width: 100%;
  border-radius: 5px;
  box-shadow: #6f4bf226 0px 0px 0px 1px;
  transition: 0.2s;
  &:focus {
    outline: none;
    box-shadow: #6f4bf233 0px 0px 0px 2px;
  }
}
