@import "../../styles/variables";

footer {
  background-color: $bg-light;
  border-top: 1px solid $border-color;
  color: $blue;

  .copyright-container {
    display: flex;
    gap: 0.3rem;
  }

  h2 {
    font-weight: 600;
    font-size: 0.9rem;
  }

  p {
    font-size: 0.8rem;
  }

  .copyright {
    width: 0.9rem;
  }
}
