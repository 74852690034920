@import "../../styles/variables.scss";

.checkout-main {
  .inner-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    @media screen and (max-width: $medium) {
      flex-direction: column-reverse;
    }
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 400;
    color: $blue;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.3rem;
    font-weight: 400;
    color: $blue;
  }

  .btn {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-top: 2rem;
  }
}

.total {
  display: flex;
  gap: 2rem;
  padding-top: 0.5rem;

  p {
    font-size: 1.5rem;
  }
}

.amount {
  border-bottom: 1px solid $purple;
  padding-bottom: 0.5rem;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
