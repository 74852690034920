@import "../../styles/variables";

nav {
  display: flex;
  flex: 1;
  gap: 2rem;
  font-size: 1.1rem;
  justify-content: flex-end;

  .link-container {
    gap: 2rem;
    display: flex;
  }

  .nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  .nav-link {
    text-decoration: none;
    color: $nav-text-color;

    &:hover {
      color: $nav-hover-color;
    }
  }

  .hamburger {
    padding: 5px;
    border: 1px solid $purple;
    background-color: $bg-light;
    border-radius: 5px;
    cursor: pointer;
    img {
      transition: 0.1s;
      width: 1.5rem;
    }
  }

  .active-hamburger {
    transform: rotate(90deg);
  }

  @media screen and (min-width: $small) {
    .hamburger {
      display: none;
    }
  }

  @media screen and (max-width: $small) {
    .nav-container {
      position: absolute;
      transition: 0.1s;
      right: 0;
      width: 100%;
      background-color: $bg-light;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 83px;
      right: 0;
      gap: 1rem;
      height: 0;
      overflow: hidden;
    }

    .show {
      height: auto;
      padding: 20px;
      border-bottom: 1px solid $border-color;
    }

    .link-container {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  }
}
