@import "./reset";
@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

html,
body {
  font-family: poppins, arial;
}

.flex {
  display: flex;
}

.inner-container {
  max-width: 1100px;
  padding: 1rem;
  justify-content: center;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  color: $blue;
}

@media screen and (max-width: $small) {
  h1 {
    font-size: 2rem;
  }
}

.btn {
  text-decoration: none;
  padding: 0.5rem;
  border: 1px solid $purple;
  color: $purple;
  border-radius: 5px;
  transition: 0.2s;
  background-color: inherit;
  cursor: pointer;

  &:hover {
    background-color: $purple;
    color: white;
  }
}
