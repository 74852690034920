@import "../../styles/variables";

.cart-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;

  .cart-amount {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: $lighter-purple;
    border-radius: 50%;
    min-width: 25px;
    min-height: 25px;
    font-size: 0.8rem;
    top: -12px;
    right: -12px;
  }

  .cart-img {
    width: 30px;
  }
}
