@import "../../styles/variables";

.cart-items {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  @media screen and (max-width: $medium) {
    flex: none;
    border-top: 1px solid $border-color;
    padding-top: 2rem;
  }
}

.cart-item {
  display: flex;
  align-items: center;
  background-color: $bg-light;
  justify-content: space-between;

  button {
    border: none;
    background-color: transparent;
  }
}

.cart-item-quantity {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .up-arrow,
  .down-arrow {
    cursor: pointer;
  }
}

.cart-item-details {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 5px;
  flex: 1;
  box-shadow: shadow;

  h2 {
    font-weight: 400;
    color: $blue;
  }

  .image-container {
    width: 5rem;
    height: 5rem;
    background-position: center;
    background-size: cover;
    border-radius: 5px 0 0 5px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
