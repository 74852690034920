@import "../../styles/variables";

.hero {
  background-image: linear-gradient(
      rgba(248, 249, 250, 0.6),
      rgba(248, 249, 250, 0.9),
      rgba(248, 249, 250, 0.9),
      rgba(248, 249, 250, 0.6)
    ),
    url("../../assets/hero.jpg");
  background-position: center;
  background-size: cover;
  height: 30rem;
  color: $blue;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 1.5rem;
  }

  .inner-hero {
    max-width: 1100px;
    width: 100%;
    padding: 1rem;
    margin-inline: auto;
    display: flex;
  }
}
