@import "../../styles/variables";

header {
  width: 100%;
  background-color: $bg-light;
  border-bottom: 1px solid $border-color;
  z-index: 1;

  .inner-header {
    justify-content: space-between;
  }

  .logo-nav {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;
  }

  .logo {
    width: 50px;
  }
}
